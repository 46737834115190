<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.employees')"
                :pgIcon="'bx bx-user'"
                :refs="refs"
                :addNew="addNew">
            </Breadcrumb>


            <DataTable
                :refs="refs"
                :colspan=10

                :hasSearchFilter=true
                :hasRoleFilter=true
                :hasDateFilter=true

                :hasHashId=true
                :hasMultiSelect=false
                :hasBulkAction=true
                :HasExport=true
                :hasShowEntries=true
                :hasSearch=true

                :lblSearch="$t('app.search_by_name_or_email_or_phone')"

                :fields="{
                    'id': 'id',
                    'name': 'name',
                    'email': 'email',
                    'mobile': 'mobile',
                    'date': 'date',
                    'status': 'status'
                }"

                :hasImage=true
                :image="$t('view.image')"
                :image_style="'width: 40px;height: 40px;border-radius: 50%'"
                
                :hasName=true
                :name="$t('view.username')"

                :hasEmail=true
                :email="$t('view.email')"

                :hasCountryCode=true
                :countryCode="$t('view.ccode')"

                :hasMobile=true
                :mobile="$t('view.mobile')"

                :hasRole=true
                :roleLabel="$t('view.role')"
                
                :hasDate=true
                :hasStatus=true
                :hasActions=true
                :hasSuspend=true

                :actionable=true
                :sortable=true

                v-on:hasAddNewPermission="handlePermissoinChange">
            </DataTable>

        </div>
    </div>

</template>

<script>
export default {
    name: 'List',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        DataTable: () => import('@/components/DataTable.vue')
    },
    data(){
        return {    
            //
            addNew: false,
            refs: 'employees',
        }
    },
    watch: {
        //
    },
    mounted() {},
    created() {
        //
    },
    methods: {
        handlePermissoinChange(event) {
            this.addNew = event;
        },
    },
}
</script>
